export let ALL_PATHS = {};

async function init() {
  const paths = await fetch('/paths.json');
  ALL_PATHS = await paths.json();
}

function chunkArray(data, chunkSize) {
  if (!chunkSize > 0) {
    // If invalid chunkSize given, just return chunkSize == data.length
    return [[...data]];
  }

  const chunks = [];

  let cursor = 0;
  while (cursor < data.length) {
    const start = cursor;
    const end = Math.min(cursor + chunkSize, data.length);

    chunks.push(data.slice(start, end));
    cursor += chunkSize;
  }

  return chunks;
}

/*
  Core call
*/
async function callApi(url, method, body) {
  try {
    const authToken = localStorage.getItem('authToken');
    const headers = { 'Content-Type': 'application/json' };

    if (authToken) {
      headers.Authorization = authToken;
    }

    const response = await fetch(url,
    {
      method,
      mode: 'cors',
      headers,
      body: body && JSON.stringify(body)
    });
    const jsonResponse = await response.json();

    /*
      If we get a 401 we should redirect to the root page
    */
    if (response.status === 401) {
      window.location.replace("/");
    }

    return ({ status: response.status, ok: response.ok, body: jsonResponse });
  }
  catch (fetchError) {
    const message = `error when performing ${method} ${url}: ${fetchError}`;
    console.error('[API]', message);
    throw new Error(message);
  }
}

/*
  Convenience functions for HTTP methods
*/
async function get(path) {
  return await callApi(path, 'GET');
}

async function post(path, body) {
  return await callApi(path, 'POST', body);
}

async function put(path, body) {
  return await callApi(path, 'PUT', body);
}

async function patch(path, body) {
  return await callApi(path, 'PATCH', body);
}

/*
  API calls
*/
export async function updateRows(isAccount, rows) {
  const callApiUpdate = isAccount ? updateAccounts : updateContacts;
  const chunkSize = 500;

  const requests = [];
  const chunks = chunkArray(rows, chunkSize);
  for (const chunk of chunks) {
    requests.push(callApiUpdate(chunk));
  }

  return await Promise.all(requests);
}

/*
  "Official" API endpoints
*/
export async function getAccounts() {
  await init();
  let allAccounts = [];
  let response;
  do {
    if (response && response.body.lastEvaluatedKey) {
      response = await get(`${ALL_PATHS.getAccountsPath}?lastEvaluatedKey=${response.body.lastEvaluatedKey.record_id}`);
    }
    else {
      response = await get(ALL_PATHS.getAccountsPath);
    }

    if (response.body && response.body.records) {
      allAccounts = allAccounts.concat(response.body.records);
    }
  } while (response.body.lastEvaluatedKey);

  return allAccounts;
}

export async function updateAccounts(data) {
  await init();
  return await put(ALL_PATHS.updateAccountPath, data);
}

export async function changeAccountStatus() {
  await init();
  return await patch(ALL_PATHS.changeAccountStatusPath);
}

export async function getContacts() {
  // return await getAndUnzip('/allContacts.zip');
  await init();
  let allContacts = [];
  let response;
  do {
    if (response && response.body.lastEvaluatedKey) {
      response = await get(`${ALL_PATHS.getContactsPath}?lastEvaluatedKey=${response.body.lastEvaluatedKey.record_id}`);
    }
    else {
      response = await get(ALL_PATHS.getContactsPath);
    }

    if (response.body && response.body.records) {
      allContacts = allContacts.concat(response.body.records);
    }
  } while (response.body.lastEvaluatedKey);

  return allContacts;
}

export async function updateContacts(data) {
  await init();
  return await put(ALL_PATHS.updateContactPath, data);
}

export async function changeContactStatus() {
  await init();
  return await patch(ALL_PATHS.changeContactStatusPath);
}

export async function uploadFiles(fileData, filename) {
  await init();
  try {
    const authToken = localStorage.getItem('authToken');
    const headers = {};

    if (authToken) {
      headers.Authorization = authToken;
    }

    const response = await fetch(`${ALL_PATHS.uploadFilesPath}/${encodeURIComponent(filename)}`,
    {
      method: 'POST',
      mode: 'cors',
      headers,
      body: fileData
    });
    const jsonResponse = await response.json();

    /*
      If we get a 401 we should redirect to the root page
    */
    if (response.status === 401) {
      window.location.replace("/");
    }

    return ({ status: response.status, ok: response.ok, body: jsonResponse });
  }
  catch (fetchError) {
    const message = `error when performing POST /ui/files: ${fetchError}`;
    console.error('[API]', message);
    throw new Error(message);
  }
}

export async function getFileHistory() {
  await init();
  return await get(ALL_PATHS.getFileHistoryPath);
}

export async function sendToCas(isAccount, rows) {
  let chunks = chunkArray(rows, 25);
  return await Promise.all(chunks.map((chunk) => {
    if (isAccount) {
      return sendAccountsToCas(chunk);
    }
    else {
      return sendContactsToCas(chunk);
    }
  }));
}

export async function sendAccountsToCas(data) {
  await init();
  console.info(`Sending ${data.length} Accounts to CAS`);
  return await post(ALL_PATHS.sendAccountsToCasPath, data);
}

export async function sendContactsToCas(data) {
  await init();
  console.info(`Sending ${data.length} Contacts to CAS`);
  return await post(ALL_PATHS.sendContactsToCasPath, data);
}

export async function getDashboardValues() {
  await init();
  return await get(ALL_PATHS.getDashboardValuesPath);
}

export async function getPickLists() {
  await init();
  return await get(ALL_PATHS.getPickListsPath);
}

export async function getQaMetrics() {
  await init();
  return await get(ALL_PATHS.getQaMetricsPath);
}

export async function getPaths() {
  await init();
  return ALL_PATHS;
}

export async function updateSetting(data) {
  await init();
  return await put(ALL_PATHS.updateSettingsPath, data);
}

export async function getSettings() {
  await init();
  return await get(ALL_PATHS.getSettingsPath);
}

export async function assignCaaNumbers(data) {
  await init();
  return await put(ALL_PATHS.assignCaaNumbersPath, data);
}

export async function createNewAccount(data) {
  await init();
  return await put(ALL_PATHS.createNewAccountPath, data);
}

export async function createNewContact(data) {
  await init();
  return await put(ALL_PATHS.createNewContactPath, data);
}
